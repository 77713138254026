import { graphql, useStaticQuery } from "gatsby";

export const useCheckoutQuery = () => {
  const data = useStaticQuery(graphql`
    query CheckoutQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
      allRlhsite(filter: { machine_name: { eq: "sonesta" } }) {
        edges {
          node {
            machine_name
            relationships {
              paragraphs: field_sections {
                type: __typename
                ...ParagraphPageBanner
                ...ParagraphPageIntro
                ...ParagraphFeaturedDestinations
                ...ParagraphMediaCta
              }
            }
          }
        }
      }
      page: nodePage(title: { eq: "Hotel Search" }) {
        id
        field_show_unlock_banner
      }
    }
  `);
  return data;
};
