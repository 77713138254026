import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { useState } from "react";
import { Card, ListGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Room } from "../../../models/room";
import { setSearch } from "../../../redux/slices/Search/search";
import RoomHeader from "../RoomHeader/RoomHeader";
import {
  container,
  addRoom,
  addRoomIcon,
} from "./ReservationCartSummary.module.scss";
import { IListGroupRoomCard } from "./ReservationCartSummaryProps";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import { navigate } from "gatsby";

export const Container: React.FC<any> = (props) => {
  return <div className={container}>{props.children}</div>;
};
export const ListGroupRoomCard: React.FC<IListGroupRoomCard> = (props) => {
  const [openRoomCard, setOpenRoomCard] = useState(
    props.activeRoomIndex == props.room.id
  );
  const currentRoomCode =
    props.currentIndexRoom &&
    props.currentIndexRoom.room &&
    props.currentIndexRoom.room.RoomCode;
  const showRoomChangeLink = props.currentIndexRoom
    ? props.room.room && currentRoomCode != props.room.room.RoomCode
    : true;

  const handleClickRoomCard = () => {
    setOpenRoomCard(!openRoomCard);
  };
  const handleSelectRoom = (eventKey: any) => {
    props.setActiveRoomIndex(eventKey);
  };
  return (
    <ListGroup.Item
      action
      eventKey={props.room.id}
      onSelect={handleClickRoomCard}
      as={Card}
      className="p-0"
    >
      <Card.Header className="d-flex flex-row border-0 position-relative">
        <div className="me-2 text-nowrap">
          <strong>Room {props.roomNumber}:</strong>
        </div>
        {props.room.room && (
          <a
            className="flex-grow-1"
            aria-expanded={openRoomCard}
          >{`${props.room.room.name}`}</a>
        )}
        <FontAwesomeIcon
          icon={`${openRoomCard ? "angle-up" : "angle-down"}`}
          className="icon"
        />
      </Card.Header>
      <Card.Body className={`p-0 ${!openRoomCard && "d-none"}`}>
        <RoomHeader
          roomIndex={props.room.id}
          checkin={props.checkout.Start}
          checkout={props.checkout.End}
          onRoomChange={handleSelectRoom}
          showRoomChangeLink={showRoomChangeLink}
        />
      </Card.Body>
    </ListGroup.Item>
  );
};
export const AddRoom: React.FC = () => {
  const dispatch = useDispatch();
  const search = useSelector((state: any) => state.search);
  const handleAddRoom = () => {
    const currentRooms = [...search.rooms];
    // const newRoom: IRoom = {
    //     id: "0",
    //     adults: 0,
    //     children: 0
    // };
    const newRoom = new Room();
    currentRooms.push(newRoom);
    dispatch(setSearch({ ...search, rooms: currentRooms }));
    navigate("/checkout");
  };
  return (
    <>
      {search.rooms.length < 3 && (
        <div className={addRoom}>
          <Button id={`add-room-button`} onClick={handleAddRoom}>
            <div className={addRoomIcon} style={{ pointerEvents: "none" }}>
              <FontAwesomeIcon icon={faPlusCircle} />
              <div className="add-room-text">Add Room</div>
            </div>
          </Button>
        </div>
      )}
    </>
  );
};
