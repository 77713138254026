import { useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { setCheckout } from "../redux/slices/Checkout/checkout";
import { convertArrayToObject } from "../utils/helpers";

import {
  addCartStackParamsToDataLayer,
  buildCartStackParams,
} from "../pageHelpers/Checkout/CheckoutHelpers";

const useUpdateCheckoutRooms = (
  checkoutReady: any,
  search: { rooms: any },
  checkout: {
    Rooms: { [s: string]: unknown } | ArrayLike<unknown>;
    HotelCode: any;
    Start: any;
    End: any;
    discount: any;
    promotionCode: any;
  },
  location: any,
  setCartstackParams: (arg0: any) => void
) => {
  const dispatch = useDispatch();

  const updateCheckoutRooms = useCallback(() => {
    let didCancel = false;

    if (checkoutReady) {
      const searchRooms = search.rooms;
      if (checkout.Rooms) {
        let updateRooms = false;
        const currentRooms = Object.values(checkout.Rooms);
        searchRooms.forEach((room, index) => {
          const match = currentRooms.find(
            (currentRoom) => currentRoom.id === room.id
          );
          if (match !== undefined) {
            if (
              room.adults !== match.adults ||
              room.children !== match.children
            ) {
              updateRooms = true;
              currentRooms[index] = {
                ...room,
                adults: room.adults,
                children: room.children,
                room: null,
                rate: null,
                services: null,
              };
            } else {
              currentRooms[index] = match;
            }
          } else {
            currentRooms[index] = {
              ...room,
              room: null,
              rate: null,
              services: null,
            };
            updateRooms = true;
          }
        });

        const removeRooms = [];
        currentRooms.forEach((room) => {
          const match = searchRooms.find(
            (searchRoom) => searchRoom.id === room.id
          );
          if (match === undefined) {
            removeRooms.push(room);
          }
        });

        if (removeRooms.length) {
          removeRooms.forEach((room) => {
            const removeIndex = currentRooms.findIndex(
              (roomItem) => roomItem.id === room.id
            );
            currentRooms.splice(removeIndex, 1);
          });
          updateRooms = true;
        }

        if (updateRooms || searchRooms.length !== currentRooms.length) {
          const newCheckout = {
            ...checkout,
            Rooms: convertArrayToObject(currentRooms, "id"),
          };

          if (!didCancel) {
            dispatch(setCheckout(newCheckout));
            addCartStackParamsToDataLayer(currentRooms, search, location);
            setCartstackParams(
              buildCartStackParams(
                checkout.HotelCode,
                checkout.Start,
                checkout.End,
                currentRooms,
                checkout.discount,
                checkout.promotionCode
              )
            );
          }
        }
      }
    }

    return () => {
      didCancel = true;
    };
  }, [checkoutReady, search.rooms]);

  useEffect(() => {
    updateCheckoutRooms();
  }, [updateCheckoutRooms]);
};

export default useUpdateCheckoutRooms;
