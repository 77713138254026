// extracted by mini-css-extract-plugin
export var alt = "CheckoutTotal-module--alt--aef89";
export var container = "CheckoutTotal-module--container--edcc1";
export var desktopOnly = "CheckoutTotal-module--desktopOnly--3419e";
export var freeStay = "CheckoutTotal-module--freeStay--4f25d";
export var main = "CheckoutTotal-module--main--ff3cb";
export var mobileOnly = "CheckoutTotal-module--mobileOnly--03456";
export var styledRow = "CheckoutTotal-module--styledRow--09be5";
export var textFree = "CheckoutTotal-module--text-free--f3529";
export var textLeft = "CheckoutTotal-module--textLeft--a771e";
export var tooltip = "CheckoutTotal-module--tooltip--950f3";
export var visuallyHidden = "CheckoutTotal-module--visuallyHidden--67204";