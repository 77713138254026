import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { Constants } from "../../../@types/Constants";
import ErrorMessageAlert from "../ErrorMessageAlert/ErrorMessageAlert";
import {
  container,
  guestInfo,
  hotelName,
  subTitle,
  title,
} from "./CheckoutGuestDetails.module.scss";

export const StyledRow: React.FC<any> = (props) => {
  return <Row className={container}>{props.children}</Row>;
};
export const StyledColHead: React.FC<any> = (props) => {
  return <Col className={container}>{props.children}</Col>;
};
export const Title: React.FC<{
  children: string | JSX.Element | JSX.Element[];
  id?: string;
}> = (props) => {
  return (
    <div id={props.id} className={title}>
      {props.children}
    </div>
  );
};
export const SubTitle: React.FC<{
  children: string | JSX.Element | JSX.Element[];
}> = (props) => {
  return <div className={subTitle}>{props.children}</div>;
};
export const HotelName: React.FC<{
  children: string | JSX.Element | JSX.Element[];
}> = (props) => {
  return (
    <div className={`mt-4 mb-5 ${hotelName}`} id="propName">
      {props.children}
    </div>
  );
};
export const GuestInfo: React.FC<{
  children: string | JSX.Element | JSX.Element[];
}> = (props) => {
  return <div className={guestInfo}>{props.children}</div>;
};

// export const CheckoutHeader = (props: any) => {
//     return (<StyledColHead>
//         <SubTitle>Reserve Your Room</SubTitle>
//         <Title id={"guest-details"}>Guest Details</Title>
//         <HotelName ><Link className={hotelTitleLink} to={props.hotel?.path?.alias}>{props.hotel.name}</Link></HotelName>
//     </StyledColHead>);
// };

export const TrackErrorMessage: React.FC<{
  message: string;
  field: string;
}> = (props) => {
  const trackMessage =
    props.message && typeof props.message !== undefined
      ? `${props.message} - ${props.field}`
      : "";
  return (
    <ErrorMessageAlert
      errorType={Constants.ERRORSTYPE.CHECKOUT}
      errorSubType={Constants.ERRORSSUBTYPE.CHECKOUT.GUESTDETAILS}
      message={trackMessage}
    >
      {props.message}
    </ErrorMessageAlert>
  );
};
