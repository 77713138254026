import React, { useState } from "react";
import { IReservationCartSummaryProps } from "./ReservationCartSummaryProps";
import {
  AddRoom,
  Container,
  ListGroupRoomCard,
} from "./ReservationCartSummaryHelpers";
import { useSelector } from "react-redux";
import { ListGroup } from "react-bootstrap";
import RoomHeader from "../RoomHeader/RoomHeader";
import { BookMoreRoomsMessage } from "../Search/GlobalOccupancy/GlobalOccupancyHelpers";

const ReservationCartSummary: React.FC<IReservationCartSummaryProps> = (
  props
) => {
  const checkout = useSelector((state: any) => state.checkout);
  const checkoutRooms: Array<any> = checkout.Rooms
    ? Object.values(checkout.Rooms)
    : [];
  const [activeRoomIndex, setActiveRoomIndex] = useState(
    (checkoutRooms[0] && checkoutRooms[0].id) || props.index
  );
  const currentIndexRoom =
    props.index && checkoutRooms.find((room) => room.id === props.index);

  const handleSelectRoom = (eventKey: any) => {
    setActiveRoomIndex(eventKey);
  };
  if (checkoutRooms.length) {
    return (
      <Container>
        <div className="mb-3 mt-lg-0 heading">Reservation Summary</div>
        {checkoutRooms.length > 1 ? (
          <ListGroup
            activeKey={activeRoomIndex}
            defaultActiveKey={activeRoomIndex}
            className="p-0 reservation-summary border-bottom"
            variant="flush"
          >
            {checkoutRooms.map((room, key) => {
              return (
                <ListGroupRoomCard
                  key={room.id}
                  room={room}
                  roomNumber={key + 1}
                  activeRoomIndex={activeRoomIndex}
                  currentIndexRoom={currentIndexRoom}
                  checkout={checkout}
                  setActiveRoomIndex={setActiveRoomIndex}
                />
              );
            })}
          </ListGroup>
        ) : (
          <RoomHeader
            roomIndex={checkoutRooms[0].id}
            checkin={checkout.Start}
            checkout={checkout.End}
            onRoomChange={handleSelectRoom}
            showRoomChangeLink={true}
          />
        )}
        {checkoutRooms.length > 2 ? (
          <BookMoreRoomsMessage isReservationCart={true} />
        ) : (
          <AddRoom />
        )}
      </Container>
    );
  } else {
    return <></>;
  }
};

export default ReservationCartSummary;
