import React, { useEffect, useState } from "react";
import { Button, Collapse, Form } from "react-bootstrap";
import { ICheckoutWithIataIdProps } from "./CheckoutWithIataIdProps";
import {
  IATA_MEMBER_ID,
  IATA_MEMBER_ID_LENGTH,
} from "../../../@types/Constants";
import { Storage } from "../../../utils/storage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { iataContainer } from "./CheckoutWithIataId.module.scss";

const CheckoutWithIataId: React.FC<ICheckoutWithIataIdProps> = (props) => {
  const [iataId, setIataId] = useState(
    Storage.GetLocalStorageValue(IATA_MEMBER_ID || null)
  );
  const [open, setOpen] = useState(!!iataId);
  const [error, setError] = useState("");

  const handleChangeIataValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIataId(e.target.value);
    props.setIataValue && props.setIataValue(e);
  };

  useEffect(() => {
    if (iataId && iataId.length !== IATA_MEMBER_ID_LENGTH) {
      setError("Please enter valid IATA number.");
    } else {
      setError("");
    }
  }, [iataId]);

  return (
    <div className={`collapsible-form-container ${iataContainer}`}>
      <div className="d-flex justify-content-between align-items-center">
        <h5 className="travelAgents">For Travel Agents</h5>
        <Button
          className="collapseButton"
          variant="outline-secondary"
          onClick={() => setOpen(!open)}
          aria-controls="iata-form"
          aria-expanded={open}
        >
          <FontAwesomeIcon icon={open ? "chevron-up" : "chevron-down"} />
        </Button>
      </div>

      <Collapse in={open}>
        <div id="iata-form">
          <>
            <Form.Group controlId="iataNumber">
              <Form.Label className="iata-label">IATA Number</Form.Label>
              <Form.Control
                type="text"
                onChange={handleChangeIataValue}
                value={iataId}
                isInvalid={error !== ""}
              />
              {error && (
                <Form.Control.Feedback type="invalid">
                  {error}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </>
        </div>
      </Collapse>
    </div>
  );
};
export default CheckoutWithIataId;
