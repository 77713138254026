import { useEffect, useState } from "react";
import Axios from "axios";
import queryString from "query-string";
import { useDispatch } from "react-redux";
import { coreGetContent } from "../services/core";
import { setSearch } from "../redux/slices/Search/search";
import { setCheckout } from "../redux/slices/Checkout/checkout";
import {
  convertArrayToObject,
  getSearchPayloadStayDates,
} from "../utils/helpers";
import {
  buildCartStackParams,
  checkSelectRoom,
  addCartStackParamsToDataLayer,
} from "../pageHelpers/Checkout/CheckoutHelpers";

import { parseSearchDate } from "../services/dates";
import IURLSearchParams from "../@types/IURLSearchParams";
import IRoom from "../@types/IRoom";
import { Room } from "../models/room";
import { Storage } from "../utils/storage";
import { IATA_EXPIRE_TIME, IATA_MEMBER_ID } from "../@types/Constants";

const useInitializeCheckout = (
  search: {
    rooms: any;
    checkoutState: {
      hotelLocation: any;
      Brand: any;
      redemptionItem: any;
      hotel: any;
      rooms: any;
    } | null;
    checkin: any;
    checkout: any;
    discount: any;
    promotionCode: any;
    groupCode: any;
    ratePlanFilterCode: any;
  },
  step: any,
  STEPS: any[],
  isWebFramed: any,
  location: {
    state: { hotel: any; rooms: any } | null;
    search: string | any[];
  },
  checkout: {
    Rooms?: any;
    hotelLocation?: any;
    Brand?: any;
    redemptionItem?: any;
  },
  setStep: (arg0: any) => void,
  setStep1page: (arg0: boolean) => void,
  setHotel: (arg0: any) => void,
  setCurrentRoomIndex: (arg0: any) => void,
  setCartstackParams: (arg0: any) => void,
  setBrandCode: (arg0: any) => void,
  setCheckoutReady: (arg0: boolean) => void
) => {
  const dispatch = useDispatch();
  useEffect(() => {
    let didCancel = false;
    let thisBrandCode = null;
    const source = Axios.CancelToken.source();

    const fetchHotel = async (crsCode: any) => {
      let fetchedHotel = null;
      const apiUrl = `/hotels?filter[status][value]=1&filter[crs_code][value]=${crsCode}&include=brand_id`;
      const data = await coreGetContent(apiUrl, source);
      if (data.data.length) {
        if (data.included) {
          thisBrandCode = data.included[0].attributes.brand_code;
        }
        const updatedRelationships = {
          ...data.data[0].attributes?.relationships,
          brand_id: data?.included?.[0]?.attributes,
        };

        fetchedHotel = {
          ...data.data[0].attributes,
          relationships: updatedRelationships,
        };
      }
      return fetchedHotel;
    };

    (async () => {
      let thisHotel = null;
      let searchRooms = search.rooms;
      let thisCurrentIndex = searchRooms[0].id;
      let currentStep = step;
      let thisCheckout = {
        hotelLocation: search?.checkoutState?.hotelLocation,
        Brand: search?.checkoutState?.Brand,
        redemptionItem: search?.checkoutState?.redemptionItem,
      };
      let searchPayload = null;

      if (isWebFramed) {
        if (location.state !== null) {
          if (location.state.hotel) {
            thisHotel = location.state.hotel;
            if (thisHotel.relationships && thisHotel.relationships.brand_id) {
              thisBrandCode = thisHotel.relationships.brand_id.brand_code;
            }
          }
          if (location?.state?.rooms) {
            searchRooms = location.state.rooms;
            window.history.replaceState(
              { ...location.state, ...{ rooms: null } },
              ""
            );
          } else {
            if (!checkout.Rooms) {
              setStep(STEPS[1]); //'select_room');
            }
            searchRooms = checkout.Rooms ? Object.values(checkout.Rooms) : [];
          }
        }
      } else {
        if (location?.state !== null || search?.checkoutState !== null) {
          if (location?.state?.hotel || search?.checkoutState?.hotel) {
            thisHotel = location?.state?.hotel ?? search?.checkoutState?.hotel;
            if (thisHotel.relationships && thisHotel.relationships.brand_id) {
              thisBrandCode = thisHotel.relationships.brand_id.brand_code;
            }
          }
          if (location?.state?.rooms || search?.checkoutState?.rooms) {
            searchRooms = location?.state?.rooms ?? search.checkoutState.rooms;
            window.history.replaceState(
              { ...location.state, ...{ rooms: null } },
              ""
            );
          } else {
            if (!checkout.Rooms) {
              setStep(STEPS[1]); //'select_room');
            }
            searchRooms = checkout.Rooms ? Object.values(checkout.Rooms) : [];
          }
        }
      }

      if (location.search.length) {
        const qp = queryString.parse(location.search, {
          parseBooleans: true,
          arrayFormat: "index",
        });

        const queryParams = Object.keys(qp).reduce((_qp, key) => {
          _qp[key.toLowerCase()] = qp[key];
          return _qp;
        }, {} as IURLSearchParams);

        if (queryParams !== null) {
          if (queryParams["iata"]) {
            Storage.SetLocalStorageValue(
              IATA_MEMBER_ID,
              queryParams?.iata,
              IATA_EXPIRE_TIME // 14 days is the validity
            );
          }
          if (queryParams.crscode) {
            thisHotel = await fetchHotel(queryParams.crscode);
            const rooms: Array<IRoom> = [];
            if (thisHotel !== null) {
              setStep1page(true);
              // Update search params to match the hotel.
              if (queryParams.rooms) {
                const _rooms = JSON.parse(queryParams.rooms);
                _rooms.forEach((room: IRoom) => {
                  const cleanedRoom = room;
                  if (cleanedRoom.adults) {
                    rooms.push(
                      new Room(
                        cleanedRoom.adults,
                        cleanedRoom.children,
                        cleanedRoom.childrenAges
                      )
                    );
                  }
                });
              }
              if (queryParams[`adult`]) {
                const adultsArr = queryParams[`adult`].split(",");
                const childrenArr = queryParams[`child`]
                  ? queryParams[`child`].split(",")
                  : [];
                const childrenAgeArr = queryParams[`childages`]
                  ? queryParams[`childages`].split(",")
                  : [];
                const roomCount = adultsArr.length;
                if (roomCount) {
                  for (let i = 0; i < roomCount; i++) {
                    const adults = parseInt(adultsArr[i] || 1) || 1;
                    const childrens = parseInt(childrenArr[i] || 0);
                    const totalGuests = adults + childrens;
                    const childrenAges = [];
                    const allRoomChildrenAges = childrenAgeArr[i]
                      ? childrenAgeArr[i].split("|")
                      : [];
                    for (let j = 0; j < childrens; j++) {
                      const ageFromUrl = allRoomChildrenAges[j];
                      const childAge =
                        ageFromUrl && ageFromUrl >= 0 && ageFromUrl < 18
                          ? ageFromUrl
                          : "";
                      childrenAges.push(childAge);
                    }
                    totalGuests <= 8 &&
                      rooms.push(new Room(adults, childrens, childrenAges));
                  }
                  searchRooms = rooms;
                }
              }
              const checkinDate = queryParams.checkin
                ? parseSearchDate(queryParams.checkin)
                : undefined;
              const checkoutDate = queryParams.checkout
                ? parseSearchDate(queryParams.checkout)
                : undefined;
              const isGroupCode = queryParams.isgroupcode
                ? queryParams.isgroupcode === true
                : false;
              const groupCode = queryParams.groupcode
                ? queryParams.groupcode.trim().toUpperCase()
                : null;
              const promoCode = queryParams.promocode
                ? queryParams.promocode.trim().toUpperCase()
                : null;
              const rateCode = isGroupCode ? groupCode : promoCode;
              const ratePlanFilterCode = queryParams.rateplanfiltercode
                ? queryParams.rateplanfiltercode.trim().toUpperCase()
                : null;
              const lengthOfStay = queryParams.los ? queryParams.los : null;
              const leadDays = queryParams.ld ? queryParams.ld : null;

              searchPayload = {
                ...search,
                searchString: thisHotel.name,
                latitude: thisHotel.lat_lon.lat,
                longitude: thisHotel.lat_lon.lon,
                rooms: rooms.length ? rooms : [new Room()],
              };

              if (checkinDate || checkoutDate || lengthOfStay || leadDays) {
                const { formedCheckin, formedCheckout } =
                  getSearchPayloadStayDates(
                    checkinDate,
                    checkoutDate,
                    lengthOfStay,
                    leadDays,
                    null
                  );
                if (formedCheckin && formedCheckout) {
                  searchPayload.checkin = formedCheckin;
                  searchPayload.checkout = formedCheckout;
                }
              }
              if (rateCode !== null) {
                if (isGroupCode) {
                  searchPayload.discount = null;
                  searchPayload.promotionCode = null;
                  searchPayload.groupCode = rateCode;
                } else {
                  searchPayload.promotionCode = rateCode;
                  searchPayload.discount = null;
                  searchPayload.groupCode = null;
                  searchPayload.ratePlanFilterCode = null;
                }
              }
              if (ratePlanFilterCode !== null) {
                searchPayload.ratePlanFilterCode = ratePlanFilterCode;
              }
            }
          }
        }
      }

      if (
        thisHotel === null ||
        thisHotel.crs_name === null ||
        thisHotel.crs_code === null
      ) {
        currentStep = STEPS[0]; //"empty";
      } else {
        const selectRoomIndex = checkSelectRoom(searchRooms);
        if (selectRoomIndex !== null || searchRooms.length === 0) {
          currentStep = STEPS[1]; //'select_room';
          thisCurrentIndex = selectRoomIndex;
        } else if (step !== STEPS[3]) {
          //finish state
          currentStep = STEPS[2]; //'guest_details';
        } else {
          currentStep = step;
        }
        thisCheckout = {
          ...checkout,
          Start: searchPayload ? searchPayload.checkin : search.checkin,
          End: searchPayload ? searchPayload.checkout : search.checkout,
          discount: searchPayload ? searchPayload.discount : search.discount,
          promotionCode: searchPayload
            ? searchPayload.promotionCode
            : search.promotionCode,
          groupCode: searchPayload ? searchPayload.groupCode : search.groupCode,
          Crs: thisHotel.crs_name,
          HotelCode: thisHotel.crs_code,
          PropertyCode: thisHotel.hotel_code,
          Rooms: convertArrayToObject(searchRooms, "id"),
          Step: currentStep,
          ratePlanFilterCode: searchPayload
            ? searchPayload.ratePlanFilterCode
            : search.ratePlanFilterCode,
          hotelLocation: search.checkoutState?.hotelLocation,
          Brand: search.checkoutState?.Brand,
          redemptionItem: search.checkoutState?.redemptionItem,
        };
      }

      if (!didCancel) {
        if (thisBrandCode !== null) {
          setBrandCode(thisBrandCode);
        }
        if (thisHotel !== null && thisHotel.crs_code !== null) {
          addCartStackParamsToDataLayer(searchRooms, search, location);
          setCartstackParams(
            buildCartStackParams(
              thisHotel.crs_code,
              search.checkin,
              search.checkout,
              searchRooms,
              search.discount,
              search.promotionCode
            )
          );
        }
        setStep(currentStep);
        setHotel(thisHotel);
        setCurrentRoomIndex(thisCurrentIndex);
        if (searchPayload) {
          await dispatch(setSearch(searchPayload));
        }
        await dispatch(setCheckout(thisCheckout));
        setCheckoutReady(true);
      }
    })();

    return () => {
      didCancel = true;
      source.cancel("Request cancelled for fetch hotel.");
    };
  }, []);
};

export default useInitializeCheckout;
