import React, { useEffect, useState } from "react";
import { ICheckoutSelectRoomProps } from "./CheckoutSelectRoomProps";
import { useDispatch, useSelector } from "react-redux";
import { convertArrayToObject } from "../../../utils/helpers";
import { useRoomsRates } from "../../../hooks/useRoomsRates";
import { IUseRoomDetail, useRoomDetail } from "../../../hooks/useRoomDetail";
import { sortRooms } from "../../../services/helpers";
import {
  addToCartGTMDataLayer,
  addPageTypeInGTMDataLayer,
  fireProductDetailEvent,
} from "../../../utils/datalayers";
import {
  setCheckout,
  updateRoom,
} from "../../../redux/slices/Checkout/checkout";
import { searchSelector } from "../../../redux/slices/Search/search";
import { useAppSelector } from "../../../hooks";

import merge from "lodash/merge";
import UnlockBanner from "../UnlockBanner/UnlockBanner";
import Spinner from "react-bootstrap/esm/Spinner";
import RoomCard from "../RoomCard/RoomCard";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import ReservationCartSummary from "../ReservationCartSummary/ReservationCartSummary";
import NotAvailableMessage from "../NotAvailableMessage/NotAvailableMessage";
import OccupancyMessage from "../OccupancyMessage/OccupancyMessage";
import AccessibleFilter from "../HotelRooms/AccessibleFilter/AccessibleFilter";
import RoomTypeFilter from "../HotelRooms/RoomTypeFilter/RoomTypeFilter";
import { IRoomRates } from "../HotelRooms/HotelRoomsProps";
import { rowContainer } from "./CheckoutSelectRoom.module.scss";
import { Constants } from "../../../@types/Constants";
import RoomSort from "../HotelRooms/RoomSort/RoomSort";
import { hotelsSelector } from "../../../redux/slices/Hotel/hotel";
import { selectHotelRates } from "../../../redux/slices/Rate/rate";
import differenceInDays from "date-fns/differenceInDays";
import { fetchRoomsAndRatesASE } from "../../../services/rates";
import { Storage } from "../../../utils/storage";

const CheckoutSelectRoom: React.FC<ICheckoutSelectRoomProps> = (props) => {
  const dispatch = useDispatch();
  const checkout = useSelector((state: any) => state.checkout);
  const [loadingRates, _rooms, roomRates, queryHotel] = useRoomsRates(
    props.index
  );
  const search = useAppSelector(searchSelector);
  const hotels = useAppSelector(hotelsSelector);
  const rates = useAppSelector(
    selectHotelRates([checkout.HotelCode as string])
  );
  const [productDetailFired, setProductDetailFired] = useState(false);
  const [roomRatesUpdated, setRoomRatesUpdated] = useState(false);
  const hrDiscountUnlockedInStore = useAppSelector(
    (state) => state.show_special_pricing
  );
  const isLoggedIn = useAppSelector((state) => state.member.isLoggedIn);
  const hrDiscountUnlockedInStorage = Storage.GetLocalStorageValue(
    "HR-discount-unlocked"
  );
  const redemptionItem = rates[checkout.HotelCode as string]?.redemptionItem;
  const _roomDetails: IUseRoomDetail = {
    hotelCode: checkout.HotelCode,
    crsName: checkout.Crs,
  };
  const [loadingRoomDetail, _roomDetail, allRoomsWithoutRates] =
    useRoomDetail(_roomDetails);

  const [roomRatesUpdatedAvailability, setRoomRatesUpdatedAvailability] =
    useState(roomRates);

  const checkoutRoomsArray = Object.values(checkout.Rooms);
  const roomIndex = checkoutRoomsArray.findIndex((r) => r.id == props.index);
  const [roomsToDisplay, setRoomsToDisplay] = useState<Array<any> | null>(null);
  const [showNoRoomsMessage, setShowNoRoomsMessage] = useState(false);

  const [filterRoomType, setFilterRoomType] = useState("");
  const [accessibleFilter, setAccessibleFilter] = useState(false);
  const [filteredRooms, setFilteredRooms] = useState([]);
  const [showRoomTypeFilter, setShowRoomTypeFilter] = useState(false);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortOrderValue, setSortOrderValue] = useState("Lowest Price");
  const getProductForDL = () => {
    let selectedRoom = null;
    let price = null;
    let bestRateCode = null;
    let baseRate = null;
    let isThisHotelPromoValid = null;
    let isPromoValid = null;
    const crs = props.hotel.crs_code;
    if (crs && rates[crs as string]?.Rooms) {
      const parsedRooms = fetchRoomsAndRatesASE(
        rates[crs as string].Rooms,
        search.discount,
        search.promotionCode,
        search.groupCode,
        search.checkin,
        search.checkout,
        redemptionItem
      );
      if (parsedRooms !== null) {
        selectedRoom = parsedRooms[0];
        price = parsedRooms[0] ? parsedRooms[0].FromRate : null;
        bestRateCode = parsedRooms[0] ? parsedRooms[0].FromRateCode : null;
        baseRate = parsedRooms[0] ? parsedRooms[0].BaseRate : null;
        if (search.promotionCode || search.groupCode) {
          isThisHotelPromoValid = parsedRooms[0]
            ? parsedRooms[0].FromRatePromotional
            : null;
          if (!isPromoValid) {
            isPromoValid = isThisHotelPromoValid;
          }
        }
      }
    }
    const showUnlockButton =
      selectedRoom?.FromRateType === "member" && !isLoggedIn;
    const discountUnlocked =
      hrDiscountUnlockedInStore || hrDiscountUnlockedInStorage;
    const priceLocked = showUnlockButton && !discountUnlocked;
    const hotelBestPrice = price !== null ? parseFloat(price) : null;
    const hotelPriceForDL = priceLocked
      ? selectedRoom
        ? selectedRoom.BaseRate
        : null
      : hotelBestPrice;
    const hotelRateForDL = priceLocked
      ? selectedRoom
        ? selectedRoom.BaseRateCode
        : null
      : bestRateCode;
    return {
      name: props.hotel.name,
      hotelId: props.hotel.crs_code,
      list: search.searchString,
      category: hotelRateForDL,
      brand: props?.hotel?.relationships?.brand_id?.name,
      price: hotelPriceForDL,
      position: getHotelSrchPostionForDetail(hotels),
    };
  };

  useEffect(() => {
    setShowNoRoomsMessage(false);
    if (!loadingRates && roomRates) {
      const hasActivePromoCode = roomRates.some(
        (promoCode: any) => promoCode.FromRatePromotional
      );
      hasActivePromoCode
        ? setSortOrderValue("Promotional Price")
        : setSortOrderValue("Lowest Price");
      props.onRoomsLoad && props.onRoomsLoad(roomRates as any);
      // update rooms availability
      const _roomRates = roomRates ? roomRates : [];
      (_roomRates as Array<any>).forEach((room: any) => {
        const selectedRooms = checkoutRoomsArray.filter((cRoom: any) => {
          return cRoom.room && cRoom.room.RoomCode == room.RoomCode;
        });
        room.quantity = room.quantity - selectedRooms.length;
      });
      setRoomRatesUpdatedAvailability(_roomRates);
      setRoomRatesUpdated(true);
    } else {
      setRoomRatesUpdatedAvailability(null);
      setRoomRatesUpdated(true);
    }
  }, [roomRates]);

  useEffect(() => {
    if (
      roomRatesUpdated &&
      props.step1page &&
      !productDetailFired &&
      !loadingRates
    ) {
      const resultProductForDetail = getProductForDL();
      fireProductDetailEvent(resultProductForDetail, search.searchString);
      setProductDetailFired(true); // Set the state to indicate that the event has been fired
    }
  }, [roomRatesUpdated, productDetailFired, loadingRates]);

  useEffect(() => {
    if (allRoomsWithoutRates) {
      if (roomRatesUpdatedAvailability) {
        const ratesObject = convertArrayToObject(
          roomRatesUpdatedAvailability,
          "RoomCode"
        );
        Object.keys(ratesObject).map((key: string) => {
          if (allRoomsWithoutRates && allRoomsWithoutRates[key]) {
            ratesObject[key].RoomType = ratesObject[key].amenities.includes(
              "Suite"
            )
              ? "Suites"
              : "Rooms";
            // modifying galleryImages in room object according to Image carousel format
            ratesObject[key].galleryImages = allRoomsWithoutRates[key].imageUrls
              .length
              ? allRoomsWithoutRates[key].imageUrls.map((image: string) => ({
                  url: image,
                  alt: image,
                }))
              : [];
          }
        });
        merge(ratesObject, allRoomsWithoutRates);
        let showTab = false;
        let allRoomTypes =
          ratesObject &&
          Object.keys(ratesObject).map((k: string) => ratesObject[k].RoomType);
        allRoomTypes = allRoomTypes.flat();
        if (
          allRoomTypes.includes(Constants.ROOM_AMINITY_ID) &&
          allRoomTypes.includes(Constants.SUITE_AMINITY_ID)
        ) {
          showTab = true;
        }
        setShowRoomTypeFilter(showTab);
        const mergedRoomsArray = Object.values(ratesObject);
        const sortedRooms = sortRooms(mergedRoomsArray); //mergedRoomsArray.sort(r => r.Availability > 0 && -1 );
        setRoomsToDisplay(sortedRooms);
      } else {
        setRoomsToDisplay(null);
        setShowNoRoomsMessage(true);
        // setRoomsToDisplay(sortRooms(Object.values(allRoomsWithoutRates)));
      }
    }
  }, [allRoomsWithoutRates, roomRatesUpdatedAvailability]);

  useEffect(() => {
    addPageTypeInGTMDataLayer("checkout select room");
    const resultProductForDetail = getProductForDL();
    fireProductDetailEvent(resultProductForDetail, search.searchString);
  }, []);

  const getHotelSrchPostionForDetail = (hotels) => {
    const hotelList = Object.values(hotels);
    hotelList.sort((a, b) => a?.index - b?.index);
    const index = hotelList.findIndex(
      (hotel) => hotel.name === props.hotel.name
    );
    return index === -1 ? 1 : index + 1;
  };

  const handleSelectRoom = async (event: any) => {
    const numOfNights = differenceInDays(
      new Date(search.checkout),
      new Date(search.checkin)
    );
    const pointsRequiredPerDay =
      queryHotel?.redemptionItem && queryHotel?.redemptionItem?.currencyRequired
        ? parseInt(redemptionItem?.currencyRequired)
        : 0;
    await dispatch(
      setCheckout({
        ...checkout,
        hotelLocation: queryHotel?.hotelLocation,
        Brand: queryHotel?.brand.code,
        redemptionItem: queryHotel?.redemptionItem,
        requiredPoints: pointsRequiredPerDay * numOfNights,
      })
    );
    const dataset = event.target.dataset;
    const roomCode = dataset.room;
    const isUnlockButton = dataset.isunlockbutton == "true";
    const room = roomRatesUpdatedAvailability
      ? (roomRatesUpdatedAvailability as Array<any>).filter(
          (roomRate) => roomRate.RoomCode === roomCode
        )[0]
      : [];
    const rateCode = isUnlockButton ? room.BaseRateCode : room.FromRateCode;
    const rate = room.Rates.filter(
      (rateObject: any) => rateObject.RateCode === rateCode
    )[0];
    let currentRoom = checkout.Rooms[props.index];
    currentRoom = { ...currentRoom, rate: rate, room: room, services: null };
    const roomRenderedPrice = dataset.roomprice;
    addToCartGTMDataLayer(
      props.hotel,
      room,
      rate,
      roomRenderedPrice,
      checkout.Start,
      checkout.End,
      checkoutRoomsArray?.length
    );
    fireProductDetailEvent(
      {
        name: props.hotel.name,
        hotelId: props.hotel.crs_code,
        list: search.searchString,
        category: rateCode,
        brand: props?.hotel?.relationships?.brand_id?.name,
        price: rate?.roomRate,
        position: getHotelSrchPostionForDetail(hotels),
      },
      search.searchString
    );

    await dispatch(updateRoom(currentRoom));
  };

  useEffect(() => {
    let roomArray = roomsToDisplay ?? [];
    if (roomArray && roomArray.length) {
      roomArray = roomArray.filter((room: IRoomRates) => {
        return room.quantity > 0;
      });

      if (filterRoomType) {
        roomArray = roomArray.filter((room: IRoomRates) => {
          //Handling the case Where the details API response contains rooms which has empty/null amenities or doesn't contain either of rooms or suites as amenity. These rooms will be shown for all Room Type filter options
          return room.RoomType?.length === 0 ||
            room.RoomType?.includes(null) ||
            !["Suites", "Rooms"].some((element) =>
              room.RoomType?.includes(element)
            )
            ? true
            : room.RoomType && room.RoomType.includes(filterRoomType);
        });
      }

      const filterAccessibleRooms = (fieldValue: boolean) =>
        roomArray.filter((room: IRoomRates) => {
          return room.accessible === fieldValue;
        });

      if (!accessibleFilter) {
        const filterByAccessible = filterAccessibleRooms(false);

        roomArray = [...filterByAccessible];
      } else {
        setAccessibleFilter(true);
      }
    }
    // Sort based on selected sort option
    if (sortOrderValue === "Promotional Price") {
      // Separate promoRooms and remainingRooms based on promoCode
      const promoRooms = roomArray.filter(
        (room: IRoomRates) => room.FromRatePromotional
      );
      const remainingRooms = roomArray.filter(
        (room: IRoomRates) => !room.FromRatePromotional
      );
      // Sort promoRooms based on promotional rates (FromRate or BaseRate if available)
      promoRooms.sort((a: IRoomRates, b: IRoomRates) => {
        const aRate =
          a.BaseRate !== null && a.BaseRate !== undefined
            ? a.BaseRate
            : a.FromRate;
        const bRate =
          b.BaseRate !== null && b.BaseRate !== undefined
            ? b.BaseRate
            : b.FromRate;
        return sortOrder === "asc" ? aRate - bRate : bRate - aRate;
      });

      remainingRooms.sort((a: IRoomRates, b: IRoomRates) => {
        const aRate =
          a.BaseRate !== null && a.BaseRate !== undefined
            ? a.BaseRate
            : a.FromRate;
        const bRate =
          b.BaseRate !== null && b.BaseRate !== undefined
            ? b.BaseRate
            : b.FromRate;

        return sortOrder === "asc" ? aRate - bRate : bRate - aRate;
      });

      // Merge promoRooms and remainingRooms back together
      roomArray = [...promoRooms, ...remainingRooms];
    } else if (sortOrderValue === "Lowest Price") {
      // Sort by BaseRate in ascending or descending order
      roomArray.sort((a: IRoomRates, b: IRoomRates) =>
        sortOrder === "asc" ? a.BaseRate - b.BaseRate : b.BaseRate - a.BaseRate
      );
    } else if (sortOrderValue === "Highest Price") {
      // Sort by BaseRate in ascending or descending order
      roomArray.sort((a: IRoomRates, b: IRoomRates) =>
        sortOrder === "asc" ? b.BaseRate - a.BaseRate : a.BaseRate - b.BaseRate
      );
    }
    // Update the rooms state with the sorted rooms
    setFilteredRooms(roomArray);
  }, [
    filterRoomType,
    accessibleFilter,
    roomsToDisplay,
    sortOrder,
    sortOrderValue,
  ]);

  const handleSortChange = (sortOrder: string) => {
    setSortOrder(sortOrder);
  };
  useEffect(() => {
    if (roomsToDisplay && roomsToDisplay.length) {
      let filterRoomsByAccessible = roomsToDisplay.filter(
        (room: IRoomRates) => room.accessible === accessibleFilter
      );
      if (!filterRoomsByAccessible.length) {
        filterRoomsByAccessible = roomsToDisplay.filter(
          (room: IRoomRates) =>
            room.Accessible === true || room.name.includes("Accessible")
        );
        setAccessibleFilter(true);
      }
    }
  }, [roomsToDisplay, loadingRates]);

  const AccessibilityRoomFilter = () => {
    return (
      <div className={rowContainer}>
        <div
          className={checkoutRoomsArray.length > 1 ? "roomSort" : "roomSort1"}
        >
          <RoomSort
            sortOrder={sortOrder}
            handleSortChange={handleSortChange}
            setSortOrderValue={setSortOrderValue}
            sortOrderValue={sortOrderValue}
          />
        </div>
        <AccessibleFilter
          accessibleFilter={accessibleFilter}
          setAccessibleFilter={setAccessibleFilter}
        />
        {showRoomTypeFilter ? (
          <RoomTypeFilter
            setFilter={setFilterRoomType}
            filterRoomType={filterRoomType}
          />
        ) : null}
      </div>
    );
  };
  const getRoomsCard = () => {
    if (filteredRooms && filteredRooms.length > 0) {
      return filteredRooms.map((room, idx) => {
        const isGroupCodeValid = room.FromRatePromotional && search.groupCode;
        return (
          <Col key={`${idx}-rc-${room.RoomCode}`} className="mb-4">
            <RoomCard
              room={room}
              selectRoom={handleSelectRoom}
              isGroupCodeValid={isGroupCodeValid}
              roomIndex={roomIndex}
              redemptionItem={redemptionItem}
              hotel={props.hotel}
            />
          </Col>
        );
      });
    } else if (showNoRoomsMessage) {
      return (
        <Col lg={{ span: 12 }} className="text-center">
          No rooms available.
        </Col>
      );
    }
  };

  if (loadingRates || loadingRoomDetail) {
    return (
      <div className="text-center">
        <Spinner animation="border" />
      </div>
    );
  } else if (roomsToDisplay && roomsToDisplay.length) {
    return (
      <React.Fragment>
        {props.unlockBannerShow ? <UnlockBanner /> : null}
        <OccupancyMessage
          page="selectroom"
          crsCode={checkout.HotelCode || ""}
          roomIndex={roomIndex}
        />

        {checkoutRoomsArray.length > 1 ? (
          <Row className="g-0">
            <Col lg={8}>
              <AccessibilityRoomFilter />
              <Row lg={2} xs={1}>
                {getRoomsCard()}
              </Row>
            </Col>
            <Col className="d-none d-lg-block ps-5">
              <ReservationCartSummary
                checkout={checkout}
                index={props.index}
                setCurrentRoomIndex={props.setCurrentRoomIndex}
              />
            </Col>
          </Row>
        ) : (
          <>
            <Row>
              <Col lg={12}>
                <AccessibilityRoomFilter />
              </Col>
            </Row>
            <Row lg={3} xs={1}>
              {getRoomsCard()}
            </Row>
          </>
        )}
      </React.Fragment>
    );
  }
  return (
    <Row>
      <Col>{showNoRoomsMessage && <NotAvailableMessage />}</Col>
    </Row>
  );
};

export default CheckoutSelectRoom;
