/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as React from "react";
import Alert from "react-bootstrap/Alert";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import CheckoutNavigation from "../../components/common/CheckoutNavigation/CheckoutNavigation";
import CheckoutSelectRoom from "../../components/common/CheckoutSelectRoom/CheckoutSelectRoom";
import SubTitle from "../../components/global/SubTitle/SubTitle";
import {
  alertIcon,
  alertMessage,
  alertRow,
  hiddenContainer,
} from "./Checkout.module.scss";
import { TP_REDEMPTION_RATE } from "../../@types/Constants";
import { postRecentSearchDetails } from "../../services/recent-search";
import { PostMessage } from "../../utils/webFramed";
import { trimOccupancyData } from "../../utils/helpers";
import { customFormatDate, formatDate } from "../../services/dates";
import { fetchRecentSearchList } from "../../redux/slices/RecentSearch/RecentSearch";
import { Recent_Booking_Updated } from "../../@types/Constants";
import { ISearchState } from "../../redux/slices/Search/search";
import IRoom from "../../@types/IRoom";
import queryString from "query-string";

export const HiddenContainer: React.FC<any> = (props) => {
  return (
    <div className={hiddenContainer}>
      <h1 className="visually-hidden">Checkout</h1>
      <div id="cartstack-params" className="visually-hidden" aria-hidden="true">
        {props.cartstackParams}
      </div>
      <div className="visually-hidden" id="propAddress" aria-hidden="true">
        {props.hotel
          ? `${props.hotel.address.address_line1},${props.hotel.address.locality},${props.hotel.address.administrative_area},${props.hotel.address.postal_code}`
          : null}
      </div>
    </div>
  );
};
export const CheckoutAlert: React.FC<{
  children: React.ReactChild;
  handleClearError(): void;
}> = (props) => {
  return (
    <Row className={`px-lg-5 mx-lg-5 ${alertRow}`}>
      <Col>
        <Alert
          variant="danger"
          className="text-center"
          dismissible
          onClose={props.handleClearError}
        >
          <Row>
            <Col className="col-1 d-flex justify-content-center">
              <span className={alertIcon}>
                <svg className="icon">
                  <use xlinkHref="/svg/error.svg#error"></use>
                </svg>
              </span>
            </Col>
            <Col>
              <span className={alertMessage}>{props.children}</span>
            </Col>
          </Row>
        </Alert>
      </Col>
    </Row>
  );
};
export const Accomendations: React.FC<{
  hotel: any;
  checkout: any;
  handleClearError(): void;
  currentRoomIndex: number;
  handleSetCurrentRoomIndex(event: any): void;
  handleOnRoomsLoad(roomsRates: any): void;
  unlockBannerShow: boolean;
  step: any;
  step1page: boolean;
}> = (props) => {
  return (
    <React.Fragment>
      <SubTitle>Accommodations</SubTitle>
      <Row className="g-0">
        <Col>
          <h1 className="mb-2 mb-lg-4">
            {Object.values(props.checkout.Rooms).length > 1
              ? "Select Your Rooms"
              : "Select Your Room"}
          </h1>
        </Col>
        <CheckoutNavigation
          rooms={Object.values(props.checkout.Rooms)}
          currentRoomKey={props.currentRoomIndex}
          setCurrentRoomIndex={props.handleSetCurrentRoomIndex}
        />
        <CheckoutSelectRoom
          index={props.currentRoomIndex}
          setCurrentRoomIndex={props.handleSetCurrentRoomIndex}
          onRoomsLoad={props.handleOnRoomsLoad}
          checkoutStep={props.step}
          hotel={props.hotel}
          unlockBannerShow={props.unlockBannerShow}
          step1page={props.step1page}
        />
      </Row>
    </React.Fragment>
  );
};

export const buildCartStackParams = (
  crsCode: string | number,
  checkin: string,
  checkout: string,
  rooms: any,
  discount = null,
  promotion = null
) => {
  const paramRooms: string[] = [];
  rooms &&
    rooms.forEach((room: any) => {
      paramRooms.push(
        JSON.stringify({ adults: room.adults, children: room.children })
      );
    });
  const paramsObject = {
    crsCode,
    checkin,
    checkout,
    rooms: paramRooms,
  };
  if (discount) {
    paramsObject.rateCode = discount;
  } else if (promotion) {
    paramsObject.rateCode = promotion;
  }
  return queryString.stringify(paramsObject, { arrayFormat: "index" });
};

// This function calculates the occupancy count and returns an object with count and array of rooms
export const calculateOccupancy = (rooms: any[]): any => {
  const occupancy = {
    count: 0,
    rooms: rooms?.map(({ id, ...rest }) => rest),
  };
  rooms.forEach((item) => {
    occupancy.count += item.adults + item.children;
  });
  return occupancy;
};

export const checkSelectRoom = (rooms: any) => {
  let selectedIndex = null;
  for (let index = 0; index < rooms.length; index++) {
    const room = rooms[index];
    if (room && !room.room) {
      selectedIndex = room.id;
    } else if (room && !room.rate) {
      selectedIndex = room.id;
    } else if (
      room &&
      room.room &&
      room.rate &&
      TP_REDEMPTION_RATE.includes(room.rate?.rateCode) &&
      rooms.length > 1
    ) {
      selectedIndex = room.id;
    }
    if (selectedIndex !== null) {
      break;
    }
  }
  return selectedIndex;
};

export const resetRoomPricing = (rooms: any) => {
  const updatedRooms = rooms;
  rooms.forEach(function (room: any, index: string | number) {
    updatedRooms[index] = {
      ...rooms[index],
      room: null,
      rate: null,
      service: null,
    };
  });
  return updatedRooms;
};

export const getBreadCrumbStep = (steps: string[], currentStep: string) => {
  let breadCrumbStep;
  switch (currentStep) {
    case steps[0]: // empty
      breadCrumbStep = 1; // hotel search
      break;
    case steps[1]: // select_room
      breadCrumbStep = 2; // Select Room
      break;
    case steps[2]: // guest_details
      breadCrumbStep = 3; // Guest details
      break;
    case steps[3]: // finish
      breadCrumbStep = 4; // Finish
      break;
    default:
      breadCrumbStep = 2;
  }

  return breadCrumbStep;
};

// This function extracts the room codes from the array of rooms
const extractRoomCodes = (rooms: any[]): string[] =>
  rooms.map((item) => item?.room?.RoomCode).filter(Boolean);

// This function posts the recent search if the search parameters are different from the previous search
export async function postRecentSearchIfNecessary(
  crmBasicProfile: any,
  checkout: any,
  search: ISearchState,
  recentSearch: any,
  isWebFramed: boolean,
  dispatch: any
) {
  if (!crmBasicProfile?.id || !isWebFramed) {
    // If CRM profile id is not available, return
    return;
  }
  const checkoutRooms: any = Object.values(checkout.Rooms);
  const { Start, End, HotelCode, promotionCode, groupCode } = checkout;
  const roomCodes = extractRoomCodes(checkoutRooms || []);
  const occupancy = calculateOccupancy(checkoutRooms || []);

  occupancy.rooms = trimOccupancyData(occupancy?.rooms); // To address the issue of the API failing due to exceeding the payload size limit in three rooms, we are trimming the data of the room and rate objects for each room.
  let specialRatePlanFilter = null;
  switch (search.discount) {
    case "AAA":
      specialRatePlanFilter = "AAA";
      break;
    case "GOV":
    case "MIL":
      specialRatePlanFilter = "GOV";
      break;
    case "PKG":
      specialRatePlanFilter = "PKG";
  }
  const filter = specialRatePlanFilter || search.ratePlanFilterCode;

  // Check if the current recent search parameters are different from the previous recent search
  const searchParamsAreDifferent =
    Start !== customFormatDate(recentSearch?.startDate) ||
    End !== customFormatDate(recentSearch?.endDate) ||
    HotelCode !== recentSearch?.hotelID ||
    recentSearch?.roomType !== roomCodes.join(",") ||
    (recentSearch?.promoCode?.trim() || null) !== promotionCode ||
    (recentSearch?.groupCode?.trim() || null) !== groupCode ||
    JSON.stringify(occupancy) != recentSearch?.occupancy ||
    recentSearch.ratePlanFilterCode !== filter;
  // If search parameters are different, post the recent search and fetch recent search list
  if (searchParamsAreDifferent) {
    const payload = {
      memberID: crmBasicProfile.id,
      startDate: Start,
      hotelID: HotelCode,
      endDate: End,
      roomType: roomCodes.join(","),
      promoCode: promotionCode || "",
      occupancy: JSON.stringify(occupancy),
      groupCode,
      ratePlanFilterCode: filter,
      lastUpdated: formatDate(new Date(), "YYYY-MM-DD"),
    };
    const result = await postRecentSearchDetails(payload);
    PostMessage({ postRecentSearchApiRes: result, apiPayload: payload });
    if (result?.insertAbandonCartMember?.statusCode === "200") {
      if (isWebFramed) {
        PostMessage({ type: Recent_Booking_Updated });
      }
      await dispatch(
        fetchRecentSearchList(crmBasicProfile.id, recentSearch.isAbandonCart)
      );
    }
  }
}

export const addCartStackParamsToDataLayer = (
  rooms: IRoom[],
  search: ISearchState,
  location: any
) => {
  const _hotel = location?.state?.hotel;
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "cartstackParameters",
    propertyName: _hotel?.name,
    roomName: rooms[0]?.room?.name,
    checkinDate_ymd: search.checkin,
    checkoutDate_ymd: search.checkout,
    checkinDate_dmy: formatDate(new Date(search.checkin), "DD-MM-YYYY"),
    checkoutDate_dmy: formatDate(new Date(search.checkout), "DD-MM-YYYY"),
    hotelBannerImageURL:
      _hotel?.relationships?.desktopImage[0]?.relationships?.field_media_image
        ?.publicUrl,
  });
};

export const checkDateUpdatesOnly = (
  prevState: ISearchState,
  currentState: ISearchState
) => {
  if (prevState && prevState?.checkin) {
    const ifDatesUpdated =
      prevState.checkin !== currentState.checkin ||
      prevState.checkout !== currentState.checkout;

    // Check if any other properties are updated
    const checkOtherPropertiesUpdate = (
      currentState: ISearchState,
      prevState: ISearchState
    ) => {
      for (const key in currentState) {
        if (
          key !== "previousState" &&
          key !== "fromPage" &&
          key !== "checkin" &&
          key !== "checkout" &&
          key !== "checkoutState" &&
          JSON.stringify(currentState[key]) !== JSON.stringify(prevState[key])
        ) {
          return true;
        }
      }
      return false;
    };

    const ifOtherPropertiesUpdated = checkOtherPropertiesUpdate(
      currentState,
      prevState
    );

    return ifDatesUpdated && !ifOtherPropertiesUpdated;
  } else {
    return false;
  }
};
