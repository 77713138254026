import * as React from "react";
import { Row } from "react-bootstrap";
import { container, styledImage, zoomIcon } from "./RoomHeader.module.scss";

export const Container: React.FC<any> = (props) => {
  return <Row className={`${container} mb-2`}>{props.children}</Row>;
};
export const StyledImage: React.FC<any> = (props) => {
  return (
    <>
      <img
        src={props.src}
        alt={props.alt}
        id={props.id}
        className={styledImage}
      ></img>
      {props.carouselImages &&
        props.carouselImages.length > 1 &&
        !props.isMultiRoom && (
          <svg
            id="gallery-icon"
            className={zoomIcon}
            onClick={() => {
              props.setShowZoomedImage(true);
            }}
          >
            <use xlinkHref="/svg/gallery-icon.svg#gallery-icon"></use>
          </svg>
        )}
    </>
  );
};
