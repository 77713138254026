import React from "react";
import { ICheckoutNavigationProps } from "./CheckoutNavigationProps";
import { StyledListGroup, StyledListItem } from "./CheckoutNavigationHelpers";
import IRoom from "../../../@types/IRoom";

const CheckoutNavigation: React.FC<ICheckoutNavigationProps> = (props) => {
  const handleSelectRoom = (eventKey: any) => {
    props.setCurrentRoomIndex(eventKey);
  };
  if (props.rooms.length > 1) {
    return (
      <StyledListGroup
        activeKey={props.currentRoomKey}
        defaultActiveKey={props.currentRoomKey}
        className="mb-4 p-0 list-group list-group-horizontal"
        as="ul"
      >
        {props.rooms.map((room: IRoom, index: any) => {
          const roomNumber = parseInt(index) + 1;
          const filledClass = room.rate ? "filled" : "";
          return (
            <StyledListItem
              index={index}
              key={index}
              room={room}
              handleSelectRoom={handleSelectRoom}
              currentRoomKey={props.currentRoomKey}
              filledClass={filledClass}
              roomNumber={roomNumber}
            ></StyledListItem>
          );
        })}
      </StyledListGroup>
    );
  }
  return null;
};

export default CheckoutNavigation;
