import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import React, { useEffect, useState } from "react";
import { Col, FormCheck } from "react-bootstrap";
import { useDispatch } from "react-redux";
import "tippy.js/dist/tippy.css";
import { updateRoom } from "../../../redux/slices/Checkout/checkout";
import AddOnsIncrementor from "../AddOnsIncrementor/AddOnsIncrementor";
import { Container, Tooltip } from "./CheckoutOptionsItemHelpers";
import { ICheckoutOptionsItemProps } from "./CheckoutOptionsItemProps";

const resolvePriceType = (priceType: string) => {
  switch (priceType) {
    case "per_stay":
    case "per_room": {
      return ["per_stay"];
    }
    case "per_item":
    case "per_person": {
      return ["per_item"];
    }
    case "per_night":
    case "per_adult_per_night":
    case "per_room_per_night": {
      return ["per_night"];
    }
    case "per_person_per_night": {
      return ["per_item", "per_night"];
    }
    default:
      return [priceType];
  }
};
const CheckoutOptionsItem: React.FC<ICheckoutOptionsItemProps> = (props) => {
  const dispatch = useDispatch();
  const inventoryCode = props.service.ServiceInventoryCode.trim();

  const priceTypeFromApi = props.service.ServicePricingType.toString()
    .toLowerCase()
    .trim()
    .replace(/\s/g, "_");
  const priceTypes = resolvePriceType(priceTypeFromApi);

  const priceInteger = Math.ceil(props.service.Price.Rate);
  const free = priceInteger === 0;
  const price = !free ? `+ $${props.service.Price.Rate}` : "Free"; // per night
  const calculatedPrice =
    priceTypes.indexOf("per_night") !== -1
      ? props.service.Price.Rate * props.nights
      : props.service.Price.Rate;
  const max =
    priceTypeFromApi === "per_person_per_night"
      ? props.service.Quantity
      : props.nights * props.occupancy;
  const itemSelected =
    free || (props.room.services && props.room.services[inventoryCode]);
  const [count, setCount] = useState(
    itemSelected &&
      props.room.services &&
      props.room.services[inventoryCode] &&
      props.room.services[inventoryCode].AvailedQuantity
      ? props.room.services[inventoryCode].AvailedQuantity
      : 1
  );

  const handleChangeCount = (index: number, count: number) => {
    setCount(count);
  };

  const handleChangeAddOns = (e: any) => {
    const ele = e.target;
    const checked = ele.checked;
    const inventoryCode = ele.value;
    const data = ele.dataset;
    const isFree = data.free !== "false";

    if (isFree) {
      e.preventDefault();
      return false;
    }

    const room = props.room;
    const service = props.service;
    const updatedServices: any = { ...room.services };

    if (checked) {
      updatedServices[inventoryCode] = {
        Description: service.Description,
        ServiceRPH: service.ServiceRPH,
        ServiceInventoryCode: service.ServiceInventoryCode,
        ServicePricingType: service.ServicePricingType,
        Price: parseFloat(data.price),
        Tax: parseFloat(data.tax),
        AvailedQuantity: count,
        Title: service?.Title,
      };
    } else {
      delete updatedServices[inventoryCode];
    }

    dispatch(updateRoom({ ...room, services: updatedServices }));
  };

  useEffect(() => {
    const room = props.room;
    if (room.services && room.services[inventoryCode]) {
      const roomService = room.services[inventoryCode];
      const updatedRoomServices = {
        ...room.services,
        [inventoryCode]: { ...roomService, AvailedQuantity: count },
      };
      dispatch(updateRoom({ ...room, services: updatedRoomServices }));
    }
  }, [count]);

  return (
    <Container>
      <Col xs="6" lg="8">
        <FormCheck>
          <FormCheck.Input
            type="checkbox"
            defaultChecked={itemSelected}
            name={props.service.Title}
            value={inventoryCode}
            onClick={handleChangeAddOns}
            data-free={free}
            data-price={calculatedPrice}
            data-tax={props.service.Price.Taxes}
          />
          <FormCheck.Label>{props.service.Title}</FormCheck.Label>
          {props.service.Description && (
            <Tippy content={props.service.Description} theme="bootstrap">
              <Tooltip>
                <FontAwesomeIcon icon={"info-circle"} className="icon" />
              </Tooltip>
            </Tippy>
          )}
        </FormCheck>
      </Col>
      <Col xs="3" lg="2">
        {priceTypes.indexOf("per_item") != -1 ? (
          <AddOnsIncrementor
            name={inventoryCode.toLowerCase()}
            value={itemSelected ? count : 1}
            onChangeCount={handleChangeCount}
            min={1}
            max={itemSelected ? max : 1}
          />
        ) : (
          <></>
        )}
      </Col>
      <Col id="offer-price" className="price">
        {price}
      </Col>
    </Container>
  );
};

export default CheckoutOptionsItem;
