import * as React from "react";
import { Row } from "react-bootstrap";
import { container } from "./UnlockBanner.module.scss";

export const Container: React.FC<any> = (props) => {
  return (
    <Row
      className={`${container} rounded ms-0 me-0 mb-4 p-3 align-items-center`}
    >
      {props.children}
    </Row>
  );
};
