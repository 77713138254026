import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from ".";
import { searchSelector } from "../redux/slices/Search/search";
import differenceInDays from "date-fns/differenceInDays";
import { selectHotelRates } from "../redux/slices/Rate/rate";
import { setCheckout, updateRoom } from "../redux/slices/Checkout/checkout";
import { getCRSRatesASE, fetchRoomsAndRatesASE } from "../services/rates";
import { useUnlockDiscount } from "./useUnlockDiscount";

export const useCheckoutRefreshAvailability = (
  roomCode: string | null,
  ifUpdateRoom: boolean,
  setStep: any,
  setStayOnGuestDetails: any
): boolean[] => {
  const dispatch = useDispatch();
  const checkout = useAppSelector((state: any) => state.checkout);
  const search = useAppSelector(searchSelector);
  const [roomRates, setRoomRates] = useState();
  const [loadingRates, setLoadingRates] = useState(false);

  const rates = useAppSelector(
    selectHotelRates([checkout.HotelCode as string])
  );

  const [queryHotel, setQueryHotel] = useState<any>(null);

  const [roomRatesUpdatedAvailability, setRoomRatesUpdatedAvailability] =
    useState(roomRates);

  const redemptionItem = rates[checkout.HotelCode as string]?.redemptionItem;

  const handleSelectRoom = async (roomCode: any) => {
    const numOfNights = differenceInDays(
      new Date(search.checkout),
      new Date(search.checkin)
    );
    const pointsRequiredPerDay =
      queryHotel?.redemptionItem && queryHotel?.redemptionItem?.currencyRequired
        ? parseInt(redemptionItem?.currencyRequired)
        : 0;
    await dispatch(
      setCheckout({
        ...checkout,
        hotelLocation: queryHotel?.hotelLocation,
        Brand: queryHotel?.brand.code,
        redemptionItem: queryHotel?.redemptionItem,
        requiredPoints: pointsRequiredPerDay * numOfNights,
      })
    );

    const room = roomRatesUpdatedAvailability
      ? (roomRatesUpdatedAvailability as Array<any>).filter(
          (roomRate) => roomRate.RoomCode === roomCode
        )[0]
      : null;
    if (room) {
      // const [showUnlockButton, _discountUnlocked, showSpecialPricing] = useUnlockDiscount(room.FromRateType);
      const isUnlockButton = true;
      const rateCode = isUnlockButton ? room.BaseRateCode : room.FromRateCode;
      const rate = room.Rates.filter(
        (rateObject: any) => rateObject.RateCode === rateCode
      )[0];
      const roomKeys = Object.keys(checkout.Rooms);
      let currentRoom = checkout.Rooms[roomKeys[0]];
      currentRoom = { ...currentRoom, rate: rate, room: room, services: null };
      setStayOnGuestDetails(false);
      await dispatch(updateRoom(currentRoom));
    } else {
      setStayOnGuestDetails(false);
      setLoadingRates(false);
      setStep("select_room");
    }

    setLoadingRates(false);
  };

  useEffect(() => {
    if (ifUpdateRoom) {
      const didCancel = false;
      // setLoadingRates(true);

      const fetchRates = async () => {
        const data = await getCRSRatesASE(
          search.rooms[0].adults,
          search.rooms[0].children,
          search.rooms[0].childrenAges,
          checkout.HotelCode,
          checkout.chainId,
          search.checkin,
          search.checkout,
          "",
          search.promotionCode,
          search.groupCode,
          search.discount || search.ratePlanFilterCode || "Retail",
          abortController
        );
        if (data) {
          setQueryHotel(data);
          const thisRoomRates = await fetchRoomsAndRatesASE(
            data.rooms,
            search.discount || "",
            search.promotionCode,
            search.groupCode,
            search?.checkin,
            search?.checkout,
            data?.redemptionItem
          );
          if (thisRoomRates) {
            setRoomRatesUpdatedAvailability(thisRoomRates);
          } else {
            setStayOnGuestDetails(false);
            setLoadingRates(false);
            setStep("select_room");
          }
        } else {
          setStayOnGuestDetails(false);
          setLoadingRates(false);
          setStep("select_room");
        }
      };
      const abortController = new AbortController();
      (async () => {
        setLoadingRates(true);
        if (ifUpdateRoom && roomCode) {
          await fetchRates();
          // if (!didCancel) {
          //   setLoadingRates(false);
          // }
        }
      })();
    }
  }, [ifUpdateRoom]);

  useEffect(() => {
    if (roomRatesUpdatedAvailability) {
      handleSelectRoom(roomCode);
    }
  }, [roomRatesUpdatedAvailability]);

  return [loadingRates];
};
