// extracted by mini-css-extract-plugin
export var alertIcon = "Checkout-module--alertIcon--c425d";
export var alertMessage = "Checkout-module--alertMessage--39427";
export var alertRow = "Checkout-module--alertRow--10683";
export var alt = "Checkout-module--alt--f4f26";
export var container = "Checkout-module--container--18456";
export var desktopOnly = "Checkout-module--desktopOnly--23284";
export var hiddenContainer = "Checkout-module--hiddenContainer--46269";
export var main = "Checkout-module--main--b76f1";
export var mobileOnly = "Checkout-module--mobileOnly--9b331";
export var textLeft = "Checkout-module--textLeft--b921b";
export var visuallyHidden = "Checkout-module--visuallyHidden--1d87a";